<template>
  <v-container>
    <ValidationObserver ref="objects" v-slot="{ invalid }">
      <v-form>
        <v-row>
          <v-col cols="12" md="6">
            <ValidationProvider name="Group" rules="required">
              <v-autocomplete
                v-model="group"
                :loading="loading"
                :items="groups"
                :search-input.sync="search"
                no-data-text="Group not found"
                item-text="title"
                item-value="id"
                auto-select-first
                clearable
                cache-items
                :filter="filterData"
                flat
                hide-no-data
                hide-details
                label="Group"
                return-object
              >
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.title"></v-list-item-title>
                      <v-list-item-subtitle v-html="data.item.id"></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Subgroup" rules="required">
              <v-select
                v-model="subgroup"
                :disabled="loading"
                :items="listSubGroups"
                :menu-props="{ maxHeight: '400' }"
                item-text="title"
                item-value="id"
                label="Subgroup"
                auto-select-first
                flat
                hide-no-data
                hide-details
                return-object
                clearable
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Type" rules="required">
              <v-select
                v-model="defaultFileType"
                :items="allowedFileTypes"
                :menu-props="{ maxHeight: '400' }"
                label="Type"
                auto-select-first
                flat
              ></v-select>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="justify-end">
            <v-btn class="primary" @click="okAction()" :disabled="invalid">Export</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
  </v-container>
</template>

<script>
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { mapGetters } from 'vuex';
import store from '@/store/index';
import ReportService from '@/services/ReportService';
import { getError } from '@/utils/helpers';

extend('required', required);

export default {
  name: 'GroupExportDialog',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  computed: {
    /**
     * The mapGetters helper simply maps store getters to local computed properties:
     */
    ...mapGetters('group', ['loading', 'groups', 'error']),
    /**
     * Get the subgroups from the group
     *
     * @returns array
     */
    listSubGroups() {
      if (this.group) {
        return this.group.subgroups;
      }
      return [];
    }
  },
  props: {
    /**
     * The prefix to use for the report file name
     */
    reportFilenamePrefix: {
      type: String,
      default: 'Certificate of Attendence'
    },
    /**
     * Name used for report in Laravel REST API
     */
    reportName: {
      type: String,
      default: 'certificate-of-attendence'
    },
    /**
     * File types allowed for export
     */
    allowedFileTypes: {
      type: Array,
      default: () => ['pdf']
    },
    /**
     * The default file type to select for the export
     */
    defaultFileType: {
      type: String,
      default: 'pdf'
    }
  },
  watch: {
    search(value) {
      if (this.loading) return false;
      if (value == null || value == '') return false;
      if (value && value.indexOf(',') > 0) return false;
      this.searchTerm = value;
      this.fetchEntriesDebounced();
    }
  },
  methods: {
    /**
     * Filter autocomplete, so we can select via id or title
     */
    filterData(item, queryText) {
      return item.title.toLowerCase().includes(queryText.toLowerCase()) || item.id == queryText.toLowerCase();
    },
    /**
     * Fetch the groups with a debounced event to reduce calls to server
     */
    fetchEntriesDebounced() {
      clearTimeout(this.timerID);
      this.timerID = setTimeout(() => {
        this.searchGroup();
      }, 1000);
    },
    /**
     * Search for the groups
     */
    searchGroup() {
      store.dispatch('group/search', {
        keyword: this.searchTerm
      });
    },
    /**
     * Create the file name for the export
     */
    filename() {
      let filename = this.reportFilenamePrefix + ' ' + this.group.title + ' ' + this.subgroup.title;
      return filename.replace(/[^a-z0-9]/gi, '-') + '.' + this.defaultFileType;
    },
    /**
     * Save method for the form
     */
    async okAction() {
      ReportService.getReport({
        report: this.reportName,
        filename: this.filename(),
        filetype: this.defaultFileType,
        subgroup_id: this.subgroup.id
      }).catch(error => {
        store.dispatch('ui/snackbarError', getError(error));
      });
    }
  },
  data: () => ({
    group: null,
    subgroup: null,
    search: null,
    timerID: null,
    searchTerm: null
  })
};
</script>
